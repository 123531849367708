/* You can add global styles to this file, and also import other style files */

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

html {
    min-height: 100vh;
    overflow: hidden;
    background-color: #333;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.mat-mini-fab.mat-accent {
    background-color: rgba(20, 20, 20, 0.40);
    color: white;
}

.tile {
    position: relative;
    box-sizing: border-box;
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: .6rem;
    cursor: pointer;
    background-color: #323232;
    transition: transform 300ms ease, color 300ms ease;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.30), 0px 0px 15px -5px black;
}

.tile.selected {
    transform: scale(1.15);
    z-index: 2;
}

.tile.selected .mat-icon {
    text-shadow: 0px 0px 2px rgba(255, 255, 255, 30%);
}

.tile:before,
.tile:after {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.tile:before {
    filter: hue-rotate(-30deg) brightness(1.1);
    -webkit-mask: linear-gradient(to bottom right, #fff, transparent);
    mask: linear-gradient(to bottom right, #fff, transparent);
}

.tile:after {
    filter: brightness(.9);
    -webkit-mask: linear-gradient(to top left, #fff, transparent);
    mask: linear-gradient(to top left, #fff, transparent);
}

.tile.selected,
.tile:hover {
    filter: brightness(1.2)
}

.tile .mat-icon {
    transform: scale(1.5);
    margin-top: -10px;
}

.tile .mat-icon+.name {
    position: absolute;
    bottom: 5px;
}
